import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function Seo({ description, lang, meta, keywords, title }) {
  const siteMetadata = {
    author: 'Your Author Name', // Update this with actual data
  };

  const metaDescription = description || 'An automated document management platform';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | An automated document management platform`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...keywords.length > 0 ? [{ name: `keywords`, content: keywords.join(`, `) }] : [],
        ...meta,
      ]}
    >
      {/* Google Analytics */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BJKXY903R6"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-BJKXY903R6');
        `}
      </script>
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
